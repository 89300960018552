<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8 mb-4">
            <b-dropdown id="dropdown-1" text="สถานะสั่งซื้อ" class="dropdown_input_search mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.order_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()" />
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label >
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="สถานะการจ่าย" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.payment_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox"  class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label" >{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="สถานะจัดส่ง" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.delivery_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="ร้านค้า" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.store_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label >
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="channel-1" text="ช่องทาง" class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.channel_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }}</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
           
            <!-- <b-dropdown id="created_since" text="วันที่สร้างออเดอร์" class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.created_since" :key="v.date_delivery" class="li_search">
                    <input type="radio" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.text }}</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown> -->
            <div class="row mt-4">
              <div class="col-sm-3">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_order" class="w-100" input-class="form-control" placeholder="วันที่สั่งซื้อ" :append-to-body="false"></date-picker>
              </div>
              <div class="col-sm-3">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_delivery" class="w-100" input-class="form-control" placeholder="วันที่จัดส่ง" :append-to-body="false"></date-picker>
              </div>
              <div class="col-sm-2">
                <select v-model="criteria.period_filters" class="form-control">
                  <option value="0">ไม่กำหนด</option>
                  <option value="1">เช้า</option>
                  <option value="2">บ่าย</option>
                  <option value="3">เย็น</option>
                  <option value="4">รอบค่ำ</option>
                </select>
              </div>
              <div class="col-sm-3">
                <select v-model="criteria.status_inspection" class="form-control"  @change="searchHandler()">
                  <option value="">สถานะการตรวจสอบ</option>
                  <option value="1">1 รอตรวจสอบออเดอร์</option>
                  <option value="2">2 ตรวจสอบออเดอร์แล้ว</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-4 ">
            <div class="mb-4">
            <b-input-group>
              <b-input-group-append><b-input-group-text class="input_border_radius_left_top_bt"><b-icon icon="search" /></b-input-group-text></b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น รหัสลูกค้า ชื่อลูกค้า สถานะการสั่งซื้อ สถานะการจ่าย" v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()"></b-form-input>
              <b-input-group-append><b-button  size="md" text="Button" variant="primary" @click="searchHandler()" class="btn_border_radius_right_top_bt" style="width: 86px;">ค้นหา</b-button></b-input-group-append>
            </b-input-group>
            </div>
            <div class="mb-4">
              <Select2 v-model="tag" :options="tags" :settings="{allowClear: true, multiple: true, tags: true}" placeholder="แท็ก" @change="searchHandler()"/>
            </div>
          </div>
          
          <div class="col-sm-12 row mb-4">
            <div class="col-sm-4">
              <Select2Supplier :value="criteria.supplier" ref="Select2Supplier" @change="getSupplierById"></Select2Supplier>
            </div>
            <div>
              <select v-model="criteria.created_since" class="form-control"  @change="searchHandler()">
                <option value="">วันที่สร้างออเดอร์</option>
                <option v-for="option in createdSinceOptions" :key="option.day" :value="option.day">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
         
          <div class="col-12">
            <hr class="mt-5 mb-5" />
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{ countFilter() }} รายการ</strong> จาก {{ paging.rowTotal | numberIntFormat }} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.order_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`code${index}`" v-if="select.selected">
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{ select.filter_count }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.payment_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`size${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.delivery_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`color${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{ select.filter_count }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)" >
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;" ></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.store_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`color${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)" >
                  <i
                    class="fa fa-times icon-del"
                    style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"
                  ></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()" >ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-4 text-right">
      <b-button size="md" variant="primary" :to="'/order/add_new'" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-plus"></i></b-button>
      <b-button size="md" variant="warning" @click="onPrintHandlerForNewVersion()" class="style_btn"><i class="fa fa-print"></i></b-button>
      <b-button size="md" variant="danger" v-b-modal.dialog_order_remove :disabled="checkDisabledDelete()" class="style_btn" v-if="permission.actionData.flag_delete" ><i class="far fa-trash-alt"></i ></b-button>
      <b-button size="md" variant="danger" title="ยกเลิก" v-b-modal.dialog_order_cancel :disabled="checkDisabledDelete()" class="style_btn" v-if="permission.actionData.flag_update" ><i class="fa fa-times"></i ></b-button>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20" style="overflow-x: auto;">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center">
                  <th style="background-color: #FFFFFF;">เลือก</th>
                  <th @click="sortHandler('code')" style="background-color: #FFFFFF;"> รหัสการสั่งซื้อ
                    <div class="float-right" v-if="criteria.orderBy == 'code'">
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th>
                    เวลาที่สร้าง
                  </th>
                  <th style="min-width: 50px; width: 1px; background-color: #FFFFFF;">ดำเนินการ</th>
                  <th style="min-width: 50px; width: 1px; background-color: #FFFFFF;">รหัสลูกค้า</th>
                  <th @click="sortHandler('date_order')">
                    วันที่สั่งซื้อ 
                    <div class="float-right" v-if="criteria.orderBy == 'date_order'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('expected_delivery_date')">
                    วันที่จัดส่ง 
                    <div class="float-right" v-if="criteria.orderBy == 'expected_delivery_date'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('store_name')">
                    ชื่อร้านค้า
                    <div class="float-right" v-if="criteria.orderBy == 'store_name'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('customer_name')">
                    ชื่อลูกค้า
                    <div class="float-right" v-if="criteria.orderBy == 'customer_name'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('phone')">
                    เบอร์โทรศัพท์
                    <div class="float-right" v-if="criteria.orderBy == 'phone'">
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('line')">
                    Line
                    <div class="float-right" v-if="criteria.orderBy == 'line'">
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('email')">
                    Email
                    <div class="float-right" v-if="criteria.orderBy == 'email'">
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th>ซัพพลายเออร์</th>
                  <th @click="sortHandler('total')">
                    มูลค่า
                    <div class="float-right" v-if="criteria.orderBy == 'total'">
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('status_order')">
                    สถานะสั่งซื้อ
                    <div class="float-right" v-if="criteria.orderBy == 'status_order'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('status_payment')">
                    สถานะการจ่าย
                    <div class="float-right" v-if="criteria.orderBy == 'status_payment'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('status_delivery')">
                    สถานะจัดส่ง
                    <div  class="float-right" v-if="criteria.orderBy == 'status_delivery'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th @click="sortHandler('status_inspection')">
                    การตรวจสอบ
                    <div class="float-right" v-if="criteria.orderBy == 'status_inspection'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th style="min-width: 100px; width: 1px;" >Printed</th>
                  <th style="min-width: 100px; width: 1px;" >Billed</th>
                  <!-- <th style="min-width: 150px; width: 1px;" @click="sortHandler('delivery_location')">ที่อยู่จัดส่ง
                    <div class="float-right" v-if="criteria.orderBy=='delivery_location'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th> -->
                  <th style="min-width: 200px; width: 1px;" @click="sortHandler('remark')">
                    หมายเหตุ
                    <div class="float-right" v-if="criteria.orderBy == 'remark'" >
                      <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th style="min-width: 100px; width: 1px;" @click="sortHandler('issue_type')">
                    พบปัญหา
                    <div class="float-right" v-if="criteria.orderBy == 'issue_type'" >
                    <i class="fa" :class=" criteria.ascDesc == 'desc' ? ' fa-caret-up' : ' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th style="min-width: 100px; width: 1px;">Created</th>
                  <th style="min-width: 100px; width: 1px;">Quotation</th>
                  <th style="min-width: 100px; width: 1px;">Receipt</th>
                  <th style="min-width: 100px; width: 1px;">Admin Tool</th>
                  <th style="min-width: 100px; width: 1px;">ช่องทาง</th>
                </tr>
              </thead>
              <tbody>
                <!-- Add -->
                <tr
                  v-for="(data, index) in items"
                  :key="`list_orders_${index}`"
                  :id="`checkbox_order_index${index}`"
                  class="main-list"
                  :style="`${(data.status_order == 6 && permission.actionData.flag_update) ? 'background-color: #ADD8E6;' :
                    (data.status_order === 7 && permission.actionData.flag_update) ? 'background-color:  #D3D3D3;' :
                    (data.printed_datetime > data.updated && permission.actionData.flag_update) ? 'background-color:  #FFFAA0;' :
                    (data.printed_datetime <= data.updated && permission.actionData.flag_update) ? 'background-color:  #F8C8DC;' :
                    (data.printed_datetime === null && permission.actionData.flag_update) ? 'background-color:  #FFFFFF;' :
                  'background-color:  #FFFFFF;' }`"
                >
                <!-- Old
                <tr
                  v-for="(data, index) in items"
                  :key="`list_orders_${index}`"
                  :id="`checkbox_order_index${index}`"
                  class="main-list"
                > -->
                  <!-- Add -->
                  <td class="text-center checkbox_style"
                    :style="`${(data.status_order == 6 && permission.actionData.flag_update) ? 'background-color: #ADD8E6;' :
                      (data.status_order === 7 && permission.actionData.flag_update) ? 'background-color:  #D3D3D3;' :
                      (data.printed_datetime > data.updated && permission.actionData.flag_update) ? 'background-color:  #FFFAA0;' :
                      (data.printed_datetime <= data.updated && permission.actionData.flag_update) ? 'background-color:  #F8C8DC;' :
                      (data.printed_datetime === null && permission.actionData.flag_update) ? 'background-color:  #FFFFFF;' :
                    'background-color:  #FFFFFF;' }`">
                    <b-form-checkbox @change="selectedOrder(...arguments, data, index)" :name="`checkbox_order_index${data.id}`" v-model="data.selected"/>
                  </td>
                  <!-- Old
                  <td class="text-center checkbox_style">
                    <b-form-checkbox
                      @change="selectedOrder(...arguments, data, index)"
                      :name="`checkbox_order_index${data.id}`"
                      v-model="data.selected"
                    />
                  </td> -->
                  <!-- Add -->
                  <td class="text-center"
                    :style="`${(data.status_order == 6 && permission.actionData.flag_update) ? 'background-color: #ADD8E6;' :
                      (data.status_order === 7 && permission.actionData.flag_update) ? 'background-color:  #D3D3D3;' :
                      (data.printed_datetime > data.updated && permission.actionData.flag_update) ? 'background-color:  #FFFAA0;' :
                      (data.printed_datetime <= data.updated && permission.actionData.flag_update) ? 'background-color:  #F8C8DC;' :
                      (data.printed_datetime === null && permission.actionData.flag_update) ? 'background-color:  #FFFFFF;' :
                    'background-color:  #FFFFFF;' }`">
                    <a
                      href="javascript:void(0)"
                      class="nav-link text-underline"
                      @click="onViewHandler(data.id)"
                    >
                      {{ data.code }}</a
                    >
                  </td>
                  
                  <td class="text-center">{{ date_format(data.created,"HH:mm") }}</td>
                 
                  <td class="text-center"
                    :style="`${(data.status_order == 6 && permission.actionData.flag_update) ? 'background-color: #ADD8E6;' :
                      (data.status_order === 7 && permission.actionData.flag_update) ? 'background-color:  #D3D3D3;' :
                      (data.printed_datetime > data.updated && permission.actionData.flag_update) ? 'background-color:  #FFFAA0;' :
                      (data.printed_datetime <= data.updated && permission.actionData.flag_update) ? 'background-color:  #F8C8DC;' :
                      (data.printed_datetime === null && permission.actionData.flag_update) ? 'background-color:  #FFFFFF;' :
                    'background-color:  #FFFFFF;' }`">
                    <span
                      class="style_icon_btn"
                      v-if="
                        (data.status_delivery == 0 ||
                          data.status_delivery == 1 ||
                          data.status_delivery == 2 ||
                          data.status_delivery == 3) &&
                          permission.actionData.flag_update
                      "
                    >
                      <i class="fas fa-pen"  @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>

                    <!-- ปุ่ม Confirm -->
                    <span class="style_icon_btn" v-if=" data.status_order == 0 && permission.actionData.flag_update">
                      <i class="fas fa-comments" title="รายละเอียดการสั่งซื้อ" @click="confirmOrderHandler(data)"></i>
                    </span>
                    <!--
                    <span class="style_icon_btn text-danger" v-if=" data.status_order == 0 && permission.actionData.flag_update">
                      <i class="fas fa-info" title="รายละเอียดการสั่งซื้อ" @click="dialogReviewOrder(data)"></i>
                    </span>
                  -->

                    <span  class="style_icon_btn" v-if="data.status_order == 7 && permission.actionData.flag_update">
                      <i class="fas fa-backward" title="ยกเลิกการสี่งซื้อ" @click="dialogRestoreCancel(data)"></i>
                    </span>
                    
                    <!--
                    <span class="style_icon_btn" v-if="data.status_payment == 1 && !checkStatusHidden(data) && permission.actionData.flag_update">
                      <i  class="far fa-credit-card" title="ชำระเงิน" @click="onPaymentHandler(data)">
                      </i>
                    </span>
                    -->
                    <span class="style_icon_btn" v-if="data.status_order != 7 && permission.actionData.flag_update">
                      <i class="fas fa-exclamation-circle" title="แจ้งปัญหา" @click="onIssueHandler(data)"></i>
                    </span>
                    <span class="style_icon_btn" v-if=" (data.status_order == 0 || data.status_order == 1) && permission.actionData.flag_update">
                      <i class="fas fa-check-circle" title="ยืนยันการตรวจสอบ" @click="approveHandler(data)" ></i>
                    </span>
                    <span class="style_icon_btn" v-if="(data.status_payment == 2 || data.status_payment == 3) && !checkStatusHidden(data) && permission.actionData.flag_update">
                      <i class="fas fa-hand-holding-usd" title="การคืนเงิน" @click="openModalRefund(data)"></i>
                    </span>
                    <span class="style_icon_btn">
                      <i class="fas fa-list" title="รายละเอียด" @click="onViewDeatailHandler(data.id)"></i>
                    </span>
                  </td>
                  <td class="text-center">{{ data.customer_id }}</td>
                  <td class="text-center">{{ data.date_order }}</td>
                  <td class="text-center">
                  
                    <!-- แสดงสีเฉพาะวันนี้  -->
                    <span :class="data.expected_delivery_date==today?'text-primary font-weight-bold':''">{{ data.expected_delivery_date }}</span>
                    {{ data.expected_delivery_time }}
                  </td>
                  <td class="text-center">{{ data.store_name }}</td>
                  <td class="text-center">{{ data.customer_name }}</td>
                  <td class="text-center">{{ data.phone }}</td>
                  <td class="text-center">{{ data.line }}</td>
                  <td class="text-center">{{ data.email }}</td>
                  <td class="text-center">{{ data.supplier_name && data.supplier_name.length > 0 ? data.supplier_name : "-" }}</td>
                  <td class="text-right">
                    {{ data.total || '' }}
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="StringUtils.getIconOrderStatus(data.status_order)" :title="StringUtils.getTextOrderStatus(data.status_order).text"></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="StringUtils.getIconPaymentStatus(data.status_payment)" :title="StringUtils.getTextPaymentStatus(data.status_payment).text"></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="StringUtils.getIconDeliveriesStatus(data.status_delivery)" :title="StringUtils.getTextDeliveriesStatus(data.status_delivery).text "></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="StringUtils.getIconInspectionStatus(data.status_inspection)" :title="StringUtils.getTextInspectionStatus(data.status_inspection).text" @click="onInspectionHandler(data)"></i>
                    </span>
                  </td>
                  <td class="text-center checkbox_style">
                    <span class="style_icon_btn" @click="changePrintStatus(data)"> 
                      <i :class="data.ts_printed==null?'fa fa-square text-light':'fa fa-check-square text-dark'" :title="date_format(data.ts_printed,'YYYY-MM-DD HH:mm')"></i>
                    </span>
                  </td>
                  <td class="text-center checkbox_style">
                    <span class="style_icon_btn" @click="changeBillstatus(data)"> 
                      <i :class="data.ts_billed==null?'fa fa-square text-light':'fa fa-check-square text-dark'" :title="date_format(data.ts_billed,'YYYY-MM-DD HH:mm')"></i>
                    </span>
                  </td>
                  <!-- <td class="text-center">{{data.delivery_location}}</td> -->
                  <td class="text-center">{{ data.remark }}</td>
                  <td class="text-center"> <span class="style_icon_btn" v-if="data.issue"> <i class="fas fa-exclamation-circle text-danger" :title="data.issue ? data.issue.topic : ''"></i> </span> </td>
                  <td class="text-center">{{ date_format(data.created,"YYYY-MM-DD") }}</td>
                  <td class="text-center"> <span class="style_icon_btn"> <i :class="'fas fa-print text-primary'" @click="onDownloadQuotation(data)"></i> </span> </td>
                  <td class="text-center"> <span class="style_icon_btn"> <i :class="'fas fa-print text-primary'" :title="2" @click="onDownloadReceipt(data)"  ></i> </span> </td>
                  <td class="text-center">
                    <span  class="style_icon_btn"> <i class="fas fa-copy" title="Copy" @click="dialogDuplicateOrder(data)"></i> </span>
                    <span class="style_icon_btn"> <i class="fas fa-envelope" title="Email" @click="openDialogSender(data.id)"></i> </span>
                    <span class="style_icon_btn"> <i class="fas fa-barcode" title="Email" @click="openDialogChangeCode(data.id)"></i> </span>
                    <span class="style_icon_btn"> <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i> </span>
                    <span class="style_icon_btn"> <i class="fas fa-book" @click="onEditOrderCommentHandler(data.id)" title="CS Comment"></i> </span>
                    
                  </td>
                  <td class="text-center">{{ data.channel_name }}</td>
                </tr>
                <tr class="text-center">
                  <td colspan="13" v-if="items.length === 0 && !loading">ไม่มีข้อมูล</td>
                  <td colspan="13" v-if="loading"><div class="spinner"></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-4 mt-4 custom-card">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-2">
              <h3>สถานะการสั่งซื้อ</h3>
              <div v-for="(v, index) in order_status" :key="`list_order_status_${index}`" >
                <i :class="StringUtils.getIconOrderStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-3">
              <h3>สถานะการจ่าย</h3>
              <div v-for="(v, index) in payment_status" :key="`list_payment_status_${index}`">
                <i :class="StringUtils.getIconPaymentStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-3">
              <h3>สถานะจัดส่ง</h3>
              <div v-for="(v, index) in delivery_status" :key="`list_delivery_status_${index}`">
                <i :class="StringUtils.getIconDeliveriesStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-2">
              <h3>การตรวจสอบ</h3>
              <div v-for="(v, index) in inspections_status" :key="`list_inspections_status_${index}`">
                <i :class="StringUtils.getIconInspectionStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-2">
              <h3>สถานะ Print</h3>
              <div v-for="(v, index) in print_status" :key="`list_print_status_${index}`" >
                <i :class="StringUtils.getIconPrintStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
          <select class="form-control mb-2" id="pageLimit" style="width: 80px;" v-model="paging.limit" @change="changeLimit()">
            <option v-for="data in paging.pages" :key="`paging_${data}`" :value="data">
              {{ data }}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3" v-if="paging.rowTotal > 0">
            <div class="col-xl-11 col-lg-10 mb-2 text-right" style="margin-top: 30px;">
              <label
                class="form-label"
                style="margin: 8px; font-size: 12px;font-family: Sarabun-Light;color: #818181;">
                แสดงรายการที่
                <strong>{{ (paging.page - 1) * paging.limit + 1 }} ถึง {{ paging.page * paging.limit }}</strong>
                จาก
                <strong>{{ paging.rowTotal | numberIntFormat }} รายการ </strong>
              </label>
              <paginate
                v-model="paging.page"
                :first-last-button="true"
                :page-count="paging.pageTotal"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'&lt;'"
                :next-text="'&gt;'"
                :first-button-text="'&verbar;&lt;'"
                :last-button-text="'&gt;&verbar;'"
                :click-handler="changePage"
                :container-class="
                  'pagination pagination-sm float-right m-auto paging_ul'
                "
                :page-class="'page-link'"
                :prev-class="'page-link prev_paging'"
                :next-class="'page-link next_paging'"
              ></paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label class="form-label" style="font-size: 12px; font-family: Sarabun-Light;">Go to page</label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <dialog-up-sale :item="item"
                    :channels="channels" />-->
    <dialog-order-payment ref="dialogOrderPayment" @completed="initialData" />
    <dialogConfirmedOrder
      :item="item"
      :products="products"
      :suppliers="suppliers"
      :customer="customerSelected"
      @confirmed-order="confirmHandler"
      ref="dialogOrderConfirm"
    />
    <!-- <page-order-view />  -->
    <dialogIssue ref="dialogIssue"/>
    <dialog-inspection :item="item" @inspect-completed="initialData" />
    <dialogOrderRemove :item="selected" @successFuction="initialData" />
    <dialogOrderCancel :item="selected" @successFuction="initialData" />
    <dialogOrderReview
      :item="item"
      :products="products"
      :suppliers="suppliers"
      :customer="customerSelected"
      @confirmed-order="confirmHandler"
      ref="dialogOrderReview"
    />

    <!-- Add -->
    <dialog-refund ref="dialogOrderRefund" @successFuction="initialData" :dataRow="dataRow" />
    <!-- Old
    <dialog-refund ref="dialogOrderRefund" @successFuction="initialData" /> -->
    <dialogSender :item="item" ref="dialogSender"/>
    <dialogChangeCode :item="item" ref="dialogChangeCode"/>
    <dialog-order-remark ref="dialogOrderRemark" />
    <dialog-order-comment ref="dialogOrderComment" />
  </div>
</template>
<script>
import dialogOrderPayment from "./dialog.order.payment";
import $ from "jquery";
import dialogConfirmedOrder from "./dialog.confirmed.order";
import dialogOrderReview from "./dialog.order.review";
import dialogSender from "./dialog.sender";
import dialogChangeCode from "./dialog.changecode";
import dialogIssue from "../../issue/view/dialog.issue";
import dialogOrderRemove from "./dialog.order.remove.vue";
import dialogOrderCancel from "./dialog.order.cancel.vue";
import Config from "../../../util/config";
import StringUtils from "../../../util/StringUtils";
import Constants from "../../../util/constants";
import mock from "../mock/order";
import dialogInspection from "./dialog.inspection";
import dialogRefund from "./dialog.refund";
import DateUtils from "../../../util/dateUtils";
import dialogOrderRemark from "./dialog.order.remark";
import dialogOrderComment from "./dialog.order.comment";
import moment from "moment";

import Select2Supplier from "../../supplier/component/select2.supplier";
export default {
  data() {
    return {
      loading: true,
      today : moment().format('DD/MM/YYYY'),
      permission: {
        appSlug: "order",
        actionData: {}
      },
      StringUtils: StringUtils,
      criteria: {
        supplier : "",
        search: "",
        orderBy: "",
        ascDesc: "",
        order_status_filters: [],
        payment_status_filters: [],
        delivery_status_filters: [],
        store_filters: [],
        channel_filters: [],
        tag_filters: [],
        dateFrom: "",
        dateTo: "",
        date_order: "",
        date_delivery: "",
        period_filters: "0",
        status_inspection: "",
        created_since: 31
      },
      order_status: Constants.order_status,
      payment_status: Constants.payment_status,
      delivery_status: Constants.deliveries_status,
      inspections_status: Constants.inspections_status,
      print_status: Constants.print_status,
      items: [],
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      channels: [],
      mock: mock,
      dialogId: 0,
      selected: [],
      issue: {
        topic: "",
        priority: "0",
        remark: "",
        issue_type: ""
      },
      item: {},
      products: [],
      suppliers: [],
      customerSelected: {},
      tags: [],
      tag: [],
      // Add
      dataRow: {
        orderId: '',
        orderDate: '',
        orderName: '',
        storeId: '',
        storeName: '',
        problems: {
          customer: '',
          supplier: '',
          cs: '',
        },
        transfer: {
          name: '',
          bank: '',
          date: '',
          time: '',
          amount: '',
          img: '',
          refund: {
            amount: '',
            bank: '',
            accountNumber: '',
            accountName: '',
            remark: '',
          },
        },
        transferBack: {
          financeCheck: '',
          refundDate: '',
          imgRefund: '',
          remark: '',
          upsaleStart: '',
          upsalePrice: '',
        },
      },
      createdSinceOptions: [
        { day: 31, text: "ข้อมูล 1 เดือนที่ผ่านมา" },
        { day: 90, text: "ข้อมูล 3 เดือนที่ผ่านมา" },
        { day: 180, text: "ข้อมูล 6 เดือนที่ผ่านมา" },
        { day: 360, text: "ข้อมูล 12 เดือนที่ผ่านมา" },
        { day: 0, text: "ข้อมูลตั้งแต่ต้น" },
      ],
    };
  },
  methods: {
    async getSupplierById(supplier_id) {
      this.criteria.supplier = supplier_id;
      this.searchHandler();
      //this.criteria.supplier_filters = [supplier_id];
    },
    date_format(date,format){
      return DateUtils.dateFormat(date,format);
    },
    disabledDateStart(date) {
      if (this.criteria.dateTo) {
        return date > this.criteria.dateTo;
      }
      return false;
    },
    disabledDateEnd(date) {
      if (this.criteria.dateFrom) {
        return date < this.criteria.dateFrom;
      }
      return false;
    },
    onPrintHandlerForNewVersion(id) {
      const selected = [];
      for (const v of this.items) {
        if (v.selected) {
          selected.push(v.id);
        }
      }

      this.SessionStorageUtils.setSession("page-order-selected", selected);
      window.open(`#/order_new/bulk_bill_new/${selected}`, '_blank');
      //this.$router.push(`/order/bulk_bill/${selected}`);
    },
    onEditHandler(id) {
      this.SessionStorageUtils.setSession("page-order", id);
      this.$router.push(`/order/edit_new/${id}`);
    },
    onViewHandler(id) {
      this.SessionStorageUtils.setSession("page-order", id);
      window.open(`#/order/view/${id}`, '_blank');
      //this.$router.push(`/order/view/${id}`);
    },
    selectedOrder(value, obj, indx) {
      if (value) {
        this.setSelectedColumn(obj);
        $(`tr#checkbox_order_index${indx}`).addClass("highlight");
      } else {
        const index = this.selected.indexOf(obj);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
        $(`tr#checkbox_order_index${indx}`).removeClass("highlight");
      }
    },
    setSelectedColumn(obj) {
      this.selected.push(obj);
    },
    openDialogSender(id){
      this.$refs.dialogSender.setID(id);
      this.$bvModal.show("dialog_sender");
    },
    openDialogChangeCode(id){
      this.$refs.dialogChangeCode.setID(id);
      this.$bvModal.show("dialog_changecode");
    },
    async dialogReviewOrder(data){
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.getData(`/ab_order/getReviewData/${data.id}`);
      if (result && result.status.code == "200") {
        this.$refs.dialogOrderReview.getItem();
        this.$bvModal.show("dialog_order_review");
      } else {
        this.AlertUtils.alert("warning", `ไม่พบข้อมูลการสั่งซื้อ`);
      }
      this.SpinnerService.hideLoader();
    },
    async confirmOrderHandler(data) {
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.getData(`/ab_order/${data.id}`);
      if (result && result.status.code == "200") {
        this.item = result.data;
        if (this.item.date_order) {
          this.item.date_order = new Date(this.item.date_order);
        }
        if (this.item.expected_delivery_date) {
          this.item.expected_delivery_date = new Date(
            this.item.expected_delivery_date
          );
        }
        if (this.item.expected_delivery_time) {
          const tmps = this.item.expected_delivery_time.split(":");
          const d = new Date(this.item.expected_delivery_date);
          d.setHours(tmps[0]);
          d.setMinutes(tmps[1]);
          this.item.expected_delivery_time = d;
        }
        if (this.item.products) {
          this.products = JSON.parse(JSON.stringify(this.item.products));
          for (const v of this.products) {
            // if(v.logoImg){
            //   v.logoImg = `${Config.apiUrl}/resources/image/${v.logoImg}`;
            // }
            if (v.imageDisplays && v.imageDisplays.length > 0) {
              // const images = [];
              // for(const vv of v.imageDisplays){
              //   images.push(`${Config.apiUrl}/resources/image/${vv}`);
              // }
              // v.imageDisplays = images;
              v.imageFiles = [];

              for (const vv of v.products) {
                // if(vv.logoImg){
                //   vv.logoImg = `${Config.apiUrl}/resources/image/${vv.logoImg}`;
                // }
                if (vv.imageDisplays && vv.imageDisplays.length > 0) {
                  // const images = [];
                  // for(const ss of v.imageDisplays){
                  //   images.push(`${Config.apiUrl}/resources/image/${ss}`);
                  // }
                  // vv.imageDisplays = images;
                  vv.imageFiles = [];
                }
              }
            }
          }
        }
        if (this.item.suppliers) {
          this.suppliers = JSON.parse(JSON.stringify(this.item.suppliers));
        }
        if (this.item.customer) {
          this.customerSelected = this.item.customer;
        }

        //delete this.item.products;
        //delete this.item.suppliers;
        //delete this.item.customer;
        
        this.$refs.dialogOrderConfirm.getItem();
        this.$bvModal.show("dialog_confirmed_order");
      } else {
        this.AlertUtils.alert("warning", `ไม่พบข้อมูลการสั่งซื้อ`);
      }
      this.SpinnerService.hideLoader();
    },
    async confirmHandler(data) {
      await this.updateStatus(1);
    },
    async approveHandler(data) {
      this.item.id = data.id;
      this.AlertUtils.confirm("warning","ยืนยันการอนุมัติออเดอร์",
        async () => {
          await this.updateStatus(2);
        }
      );
    },
    async dialogRestoreCancel(data) {
      this.item.id = data.id;
      const diffDays = Math.ceil(Math.abs(new Date() - new Date(data.sql_date_cancel)) / (1000 * 60 * 60 * 24)); 
        if(diffDays > 2 ){
          this.AlertUtils.alert("alert","เกิน 2 วันแล้วไม่คืนค่าได้");
        }else{
          this.AlertUtils.confirm("warning","ต้องการคืนใบยกเลิก",
          async () => {
            await this.restoreOrder();
          }
        );
      }
    },
    async dialogDuplicateOrder(data) {
      this.item.id = data.id;
      this.AlertUtils.confirm("warning","ต้องการที่สร้าง Duplicate Order ใหม่",async () => {await this.duplicateOrder();});
    },
    async changePrintStatus(data) {
      this.AlertUtils.confirm("warning","ต้องการที่สร้างเปลี่ยนสถานะ",async () => {await this.updatePrintStatus(data.id,data.ts_printed);});
    },
    async changeBillstatus(data) {
      this.AlertUtils.confirm("warning","ต้องการที่สร้างเปลี่ยนสถานะ",async () => {await this.updateBillStatus(data.id,data.ts_billed);});
    },
    async updatePrintStatus(id,status) {
      const params = { status: status!=null?0:1};
      const result = await this.HttpServices.putData(`/ab_order/updatePrintStatus/${id}`, params);
      if (result && result.status.code == "200") {
        this.getListItems();
        this.AlertUtils.alert("success", "สำเร็จ");
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    async updateBillStatus(id,status) {
      const params = { status: status!=null?0:1};
      const result = await this.HttpServices.putData(`/ab_order/updateBillStatus/${id}`, params);
      if (result && result.status.code == "200") {
        this.getListItems();
        this.AlertUtils.alert("success", "สำเร็จ");
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },

    
    async updateStatus(status) {
      const params = { status: status };
      const result = await this.HttpServices.putData(
        `/ab_order/updateOrderStatus/${this.item.id}`,
        params
      );
      if (result && result.status.code == "200") {
        this.initialData();
        this.AlertUtils.alert("success", "ยืนยันออเดอร์สำเร็จ");
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    async restoreOrder() {
      const params = { status:2 };
      const log_item = await this.HttpServices.putData(`/ab_order/get_previous_log/${this.item.id}`);
      if(log_item.data){
        let json = JSON.parse(log_item.data.data);
        params.status = json.ab_orders.status_order;
        console.log(json);
      }

      const result = await this.HttpServices.putData(`/ab_order/updateOrderStatus/${this.item.id}`,params);
      if (result && result.status.code == "200") {
        this.initialData();
        this.AlertUtils.alert("success", "คืนค่าสำเร็จแล้ว");
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    async duplicateOrder() {
      const result = await this.HttpServices.putData(`/ab_order/duplicateOrder/${this.item.id}`);
      if (result && result.status.code == "200") {
        this.initialData();
        this.AlertUtils.alert("success", "Duplicated Order : "+result.data.order_code);
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    onInspectionHandler(data) {
      if (data.status_inspection == 1) {
        this.item.id = data.id;
        this.item.remark_not_check = data.remark_not_check
          ? data.remark_not_check
          : "";
        this.$bvModal.show("dialog_order_inspection");
      }
    },
    async onIssueHandler(data) {
      this.$refs.dialogIssue.initIssue(1,data.id);
      this.$bvModal.show("dialog_issue");
    },
    onPaymentHandler(data) {
      this.item.id = data.id;
      this.$refs.dialogOrderPayment.initData(data);
      this.$bvModal.show("dialog_order_payment");
    },
    openModalRefund(data) {
      this.$refs.dialogOrderRefund.initRefund(data.id);
      this.$bvModal.show("dialog_order_refund");
      // Add
      let dataRow = dataRow = {
        ...data,
        ...this.dataRow,
        orderId: data.code,
        storeName: data.store_name,
      };
      console.log('dataRow ', dataRow)
      this.dataRow = dataRow;
    },

    /******* local function ******/
    changeLimit() {
      this.paging.page = 1;
      this.getListItems();
      this.saveFilter();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListItems();
      this.saveFilter();
    },
    sortHandler(orderBy) {
      if (orderBy != this.criteria.orderBy) {
        this.criteria.ascDesc = "asc";
      } else {
        this.criteria.ascDesc = this.criteria.ascDesc == "asc" ? "desc" : "asc";
      }S
      this.criteria.orderBy = orderBy;
      this.getListItems();
    },
    searchHandler() {
      this.paging.page = 1;
      this.saveFilter();
      this.getListItems();
    },
    async getListItems() {
      //this.SessionStorageUtils.setSession("page-order-criteria", this.criteria);

      //Filter : Order Status
      let filters = [];
      this.criteria.order_status_filters.map(v => {if (v.selected) {filters.push(v.id);}});
      filters = filters.join(",");

      //Filter : Payment Status
      let paymentFilters = [];
      this.criteria.payment_status_filters.map(v => {if (v.selected) {paymentFilters.push(v.id);}});
      paymentFilters = paymentFilters.join(",");

      //Filter : Delivery Status
      let deliveryFilters = [];
      this.criteria.delivery_status_filters.map(v => {if (v.selected) {deliveryFilters.push(v.id); }});
      deliveryFilters = deliveryFilters.join(",");

      //Filter : Store Status
      let storeFilters = [];
      this.criteria.store_filters.map(v => {if (v.selected) {storeFilters.push(v.id);}});
      storeFilters = storeFilters.join(",");

      //Filter : Channel Status
      let channelFilters = [];
      this.criteria.channel_filters.map(v => {if (v.selected) {channelFilters.push(v.id);}});
      channelFilters = channelFilters.join(",");


      
      let tagFilters = [];
      if(this.tag.length>0){
        for(const v of this.tag){
          tagFilters.push(v);
        }
      }
      tagFilters = tagFilters.join(",");

      //this.SpinnerService.showLoader();
      this.loading = true;
      this.items = [];
      //เพิ่ม Filter วันสั่งและวันส่ง
      let date_order = "";
      let date_delivery = "";
      if (this.criteria.date_order)
        date_order = DateUtils.dateFormat(this.criteria.date_order,"YYYY-MM-DD");
      if (this.criteria.date_delivery)
        date_delivery = DateUtils.dateFormat(this.criteria.date_delivery,"YYYY-MM-DD");
      let dateFrom = "";
      let dateTo = "";
      if (this.criteria.dateFrom) {dateFrom = DateUtils.dateFormat(this.criteria.dateFrom, "YYYY-MM-DD");}
      if (this.criteria.dateTo) {dateTo = DateUtils.dateFormat(this.criteria.dateTo, "YYYY-MM-DD");}

      console.log('this.criteria',this.criteria)

      let params = `search=${this.criteria.search}&orderStatusFilters=${filters}&paymentStatusFilters=${paymentFilters}&deliveryStatusFilters=${deliveryFilters}`;
      params += `&storeFilters=${storeFilters}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      params += `&date_order=${date_order}&date_delivery=${date_delivery}`;
      params += `&channelFilters=${channelFilters}`;
      params += `&tagFilters=${tagFilters}`;
      params += `&periodFilters=${this.criteria.period_filters}`;

      params += `&supplier_id=${this.criteria.supplier}`;
      params += `&status_inspection=${this.criteria.status_inspection}`;
      params += `&created_since=${this.criteria.created_since}`;
      
      
      const result = await this.HttpServices.getData(`/ab_order?${params}`);
      if (result && result.status.code == "200") {
        const data = result.data;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.pageTotal = total;
        this.paging.rowTotal = data.rowTotal;
        this.items = data.data;
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
      $(`tr.main-list`).removeClass("highlight");
      //this.SpinnerService.hideLoader();
      
      this.saveFilter();
      this.loading = false;
    },
    checkDisabledDelete() {
      let resp = true;
      for (const v of this.items) {
        if (v.selected) {
          resp = false;
          break;
        }
      }
      return resp;
    },
    countFilter() {
      const filters = this.criteria.order_status_filters.filter(v => v.selected);
      const filters1 = this.criteria.payment_status_filters.filter(v => v.selected);
      const filters2 = this.criteria.delivery_status_filters.filter(v => v.selected);
      const filtersStore = this.criteria.store_filters.filter(v => v.selected);
      return (filters.length + filters1.length + filters2.length + filtersStore.length);
    },
    unSelectFilter(item) {
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter() {
      this.criteria.order_status_filters.map(v => {v.selected = false;});
      this.criteria.payment_status_filters.map(v => {v.selected = false;});
      this.criteria.delivery_status_filters.map(v => {v.selected = false;});
      this.criteria.store_filters.map(v => {v.selected = false;});
      this.searchHandler();
    },
    async getFilterChannel() {
      const result = await this.HttpServices.getData(
        `/ab_order/getAllChannels`
      );

      const data = result.data;

      this.criteria.channel_filters = data;
    },
    async getFilterGroups() {
      const result = await this.HttpServices.getData(
        `/ab_order/getCountFilter`
      );
      const orders = result.data.orderStatus;
      const payments = result.data.paymentStatus;
      const deliveries = result.data.deliveryStatus;


      this.criteria.order_status_filters = [];
      this.criteria.payment_status_filters = [];
      this.criteria.delivery_status_filters = [];
      this.order_status.forEach(v => {
        let lst = null;
        orders.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.order_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
      this.payment_status.forEach(v => {
        let lst = null;
        payments.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.payment_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
      this.delivery_status.forEach(v => {
        let lst = null;
        deliveries.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.delivery_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });

      this.criteria.store_filters = result.data.store;

      this.tags = await this.HttpServices.getMasterData("/master/getTags");
    },
    checkStatusHidden(data) {
      if (data.status_order == 6 || data.status_order == 7) {
        return true;
      }
      return false;
    },
    /******* local function ******/
    async initialData() {
      await this.getFilterGroups();
      await this.getFilterChannel();
      await this.getListItems();
      this.selected = [];
    },
    onViewDeatailHandler(id) {
      this.SessionStorageUtils.setSession("page-order", id);
      this.$router.push(`/order/detail/${id}`);
    },
    onViewBillOrderHandler(id) {
      this.SessionStorageUtils.setSession("page-order", id);
      this.$router.push(`/order/bill_order/${id}`);
    },
    onViewBillBulkHandler() {
      this.$router.push(`/order/bulk_bill/${id}`);
    },
    async onDownloadQuotation(data) {
      const result = await this.HttpServices.getDataFile(
        `/ab_ebilling/order/${data.id}?docType=quotation`
      );
      console.log('result',result)
      if (result !== null) {
        const blob = new Blob([result], {
          type: "application/pdf"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `quotation-${data.code}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    },
    async onDownloadInvoice(data) {
      const result = await this.HttpServices.getDataFile(
        `/ab_ebilling/order/${data.id}?docType=invoice`
      );
      if (result !== null) {
        const blob = new Blob([result], {
          type: "application/pdf"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${data.code}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    },
    async onDownloadReceipt(data) {
      const result = await this.HttpServices.getDataFile(
        `/ab_ebilling/order/${data.id}?docType=receipt`
      );
      if (result !== null) {
        const blob = new Blob([result], {
          type: "application/pdf"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `receipt-${data.code}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    },
    saveFilter() {
      this.SessionStorageUtils.setSession("page-order-filter",JSON.stringify(this.criteria));
    },
    async loadFilter() {
      let loader = JSON.parse(await this.SessionStorageUtils.getSession("page-order-filter"));
      console.log(loader);
      this.criteria = loader;
    },
    onEditOrderRemarkHandler(order_id) {
      this.$refs.dialogOrderRemark.load_order(order_id);
      this.$bvModal.show("dialog_order_remark");
    },
    onEditOrderCommentHandler(order_id) {
      this.$refs.dialogOrderComment.load_order(order_id);
      this.$bvModal.show("dialog_order_comment");
    }
  },
  components: {
    dialogOrderPayment,
    dialogConfirmedOrder,
    dialogOrderReview,
    dialogSender,
    dialogChangeCode,
    dialogIssue,
    dialogOrderRemove,
    dialogInspection,
    dialogRefund,
    dialogOrderCancel,
    dialogOrderRemark,
    dialogOrderComment,
    Select2Supplier
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug,this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    await this.initialData();
    await this.SessionStorageUtils.deleteSession("page-order");
    await this.loadFilter();
  }
};
</script>
<style scoped>
.checkbox_style > .custom-control {
  left: 0px;
}

.dropdown_input_search > .dropdown-toggle {
  min-width: 200px;
}
.hover_pointer:hover {
  cursor: pointer;
}

/* Add */
/* tr>th:first-child,tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  
} */


tr>th:nth-child(1),tr>td:nth-child(1) {
  position: sticky;
  left: 0;
  max-width: 68px;
  background-color: #fff; 
}

tr>th:nth-child(2),tr>td:nth-child(2) {
  position: sticky;
  left: 68px;
  min-width: 230px;
  background-color: #fff; 
}
tr>th:nth-child(3),tr>td:nth-child(3) {
  position: sticky;
  left: 298px;
  background-color: #fff; 
}

tr>th:nth-child(4),tr>td:nth-child(4) {
  position: sticky;
  left: 413px;
   background-color: #fff; 
}

th, td {
  outline: 1px solid #e9ecef;
  outline-offset: -1px;
}

li.li_search{
  padding: .5rem 1rem;
  font-size:0.875rem;
  width: 260px;
}
.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
