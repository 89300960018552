<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-cart-plus"></i> ข้อมูลการสั่งซื้อ V2</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-order/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import pageOrder from "./view/page.order_new.vue";

  export default {
    components: {
      pageOrder
    }
  };
</script>
<style>

</style>
